* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #38b44e;
}

@media (display-mode: browser) {

  body,
  #root {
    height: var(--vh);
    max-height: var(--vh);
    overflow-y: hidden;
  }

}

#root {
  display: flex;
  justify-content: center;
}

#root>div {
  width: 100%;
  max-width: 700px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTablePagination-root {
  font-size: 10px !important;
}